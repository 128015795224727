<template>
   <div class="ouser-container">
    <div class="shop-container">
        <div class="shop-top">
            <p class="shop-top-text">
                <view class="iconfont">&#xe6af;</view>
                如果您有项目需求，填写以下申请信息，我们会在收到申请的一周内与您联系。</p>
        </div>
        <el-form 
            :model="form" 
            label-width="120px" 
            :rules="rulesForm"
            ref="myFormRef">
            <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="公司/单位" prop="company">
                <el-input v-model="form.company" />
            </el-form-item>
            <el-form-item label="电话" prop="number">
                <el-input v-model="form.number" />
            </el-form-item>
            <el-form-item label="地址" prop="address">
                <el-input v-model="form.address" />
            </el-form-item>
            <el-form-item label="项目名称" prop="project">
                <el-input v-model="form.project" />
            </el-form-item>
            <el-form-item label="项目描述" prop="textarea">
                <el-input
                    v-model="form.textarea"
                    :rows="3"
                    type="textarea"
                    placeholder="请对项目进行简单描述"
                />
            </el-form-item>
        </el-form>
        <div class="shop-tj">
            <el-button type="success" @click="onSubmit " class="hvr-bounce-to-right">提交</el-button>
            <!-- <button class="hvr-bounce-to-right" @click="onSubmit ">提交</button> -->
            <el-button class="primary hvr-bounce-to-right"  @click="onResetting" >重置</el-button>
        </div> 
    </div>
   </div>
</template>
<script>

export default {
    name: 'MyShop',
    data() {
        let checkNumber = ( rule, value, cb) => {
            if(!Number.isInteger(value)) {
                return cb(new Error('请输入整数'))
            }
            cb()
        }
        return {
            // 需要采集的用户信息对象
            form: {
                name: '',
                company: '',
                number: '',
                address: '',
                project: '',
                describe: '',
                textarea: '',
            },
            // 表单验证对象
            rulesForm: {
                name: [
                    { required: true, message: '此项必填', trigger: 'blur'}
                ],
                company: [
                    { required: true, message: '此项必填', trigger: 'blur'}
                ],
                number: [
                    { required: true, message: '此项必填', trigger: 'blur'},
                    { validator: checkNumber, trigger: 'blur'}
                ],
                address: [
                    { required: true, message: '此项必填', trigger: 'blur'}
                ],
                project: [
                    { required: true, message: '此项必填', trigger: 'blur'}
                ],
                describe: [
                    { required: true, message: '此项必填', trigger: 'blur'}
                ],
                textarea: [
                    // { required: true, message: '此项必填', trigger: 'blur'},
                ],
            }
        }
    },
    methods: {
        onSubmit() {
            this.$refs.myFormRef.validate((v) => {
                if(v) {
                    this.$refs.myFormRef.resetFields()
                    return this.$message.success('申请成功！')
                }

            })
        },
        onResetting() {
            this.$refs.myFormRef.resetFields()
        }
    }
}
</script>
<style scoped>
.ouser-container {
    width: 100%;
    /* background-color: #e7e8ea; */
    border-radius: 0px;
}

.shop-container {
    width:1200px;
    height: 100%;
    border-radius: 0px;
    margin: 5rem auto; 
    background-color: #fff;
    /* border: 2px solid rgb(0, 0, 0); */
}
.shop-top {
    width: 460px;
    height: 80px;
    margin: 0 auto;
}
.shop-top-text {
    font-size: 12px;
    height: 80px;
    line-height: 80px;
    width: 460px;
    margin: 0px;

}
.iconfont {
    height: 50px;
    line-height: 80px;
    font-size: 12px;
}
.el-form-item {
    margin: 50px 350px 50px 250px;
}
.shop-tj {
    width: 100%;
    height: 100px;
    margin: 0 auto;
    line-height: 50px;
    text-align: center; 
}
.el-button {
    width: 100px;
    height: 40px;
}
.el-input {
    height: 40px;
    font-size: 1rem;
}
.hvr-bounce-to-right:hover {
    color: #fff;

}
.el-form {
    height: 550px;
}




</style>