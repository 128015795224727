<template>
    <div class="goods-container">
        <!-- 第一层 -->
        
        <div class="goods-body">
            <div class="body-top">
                <div class="goods_top_bg">
                    <img class="cp_img_01" src="../images/fj.jpg" alt="">
                    <div class="goods-right-box04">
                        <span class="box01-right-title" style="color: #fff;">关于产品</span>
                                <div class="box01-text-container animate__animated animate__backInRight box-size">
                                    <span class="box01-right-text">
                                        根据民航维修生产保障模式的优化进程，航齐科技结合飞机维修工具设备使用场景和借还现状，
                                        自主研发设计智能化设备，主要有自助借还柜（箱包柜）、智能工具车（抽屉柜）、
                                        自助充电柜和移动工作站等多种核心产品，缓解了客户单位的工具借还保障压力，
                                        提高了工具借还管理的便捷性和安全裕度。
                                    </span>
                                </div>
                    </div>
                </div>

                <!-- 第三层 -->
                <!-- <p class="cp-center">核心产品</p> -->
                <div class="body-top-box01">
                    <!-- <div class="box01-text-right animate__animated animate__slideInDown">
                        <span class="box01-right-title">产品介绍</span>
                        <div class="box01-text-container">
                            <span class="box01-right-text">航齐科技最新一代TC-3型智能工具车读取速度快、准确率
                                高，可以按照客户工作需求配置工具，使用最新一代定制
                                RFID芯片，并且采用专利技术植入芯片可以有效的防止丢
                                失，芯片易损坏以及较小具无法实现RFID管理的技术难点，贴合维修实际工作需要，满足维修单位各种工作场景的工
                                具使用管理需求，特别是定检维修中，工具自助借还真正
                                意义上实现了提升效率的同时保证了工具管理安全。</span>
                        </div>
                    </div> -->
                    <div class="block text-center">
                        <el-carousel height="600px">
                            <el-carousel-item v-for="item in imagesList" :key="item.id" @change="imgChange(key)">
                                <img class="lunbo-img" :src="item.src" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>


               
                <!-- 第四层 -->
                <div class="body-box06-box">
                    <div class="body-box06"> 
                        <my-cpxn></my-cpxn>    
                    </div>
                </div>
              
                <!-- 第五层 -->
                <div>
                    <my-show></my-show>
                </div>
            </div>
            
        </div>
    </div>

</template>
<script>
import MyLunbo from '@/components/MyLunbo.vue';
import MyCpxn from '../components/CPXN.vue'
import MyShow from '../components/CP-show.vue'

export default {
    naem: 'MyGoods',
    data() {
        return {
            hoverColor: 'red',
            active: -1,
            id:'',
            goodsList: [
                {
                    id: 1,
                    src: require('../images/tims_new/41505.jpg'),
                    
                },
                {
                    id: 2,
                    src: require('../images/tims_new/41506.jpg'),
                   
                },
                {
                    id: 3,
                    src: require('../images/tims_new/41507.jpg'),
                    
                },

            ],
            imagesList: [
                {id: 1, src: require('../images/tims_new/41505.jpg')},
                {id: 2, src: require('../images/tims_new/41506.jpg')},
                {id: 3, src: require('../images/tims_new/41507.jpg')},
                {id: 4, src: require('../images/tims_new/41508.jpg')},
                {id: 5, src: require('../images/tims_new/41506.jpg')},
            ],
            display: 'none'
        }
    },
    methods: {
        mouseover() {
            this.active = 1
        },
        ShowHidden() {
            this.display = 'block'
        },
        imgChange(e) {
            console.log(e)
        }
    },
    components: {
        MyLunbo,
        MyCpxn,
        MyShow,
    }

}
</script>
<style>
.box03_hidden {
    display: none;
    width: 200px;
    height: 300px;
    background-color: yellow;
}

.cp-center {
    width: 100%;
    height: 60px;
    margin: 0 auto;
    padding: 40px 0px 20px 0px;
    text-align: center;
    line-height: 60px;
    font-size: 3rem;
    letter-spacing: 5px;
    font-weight: 700;
}

.goods-container {
    width: 100%;
    height: 100%; 
}

.goods_top_bg {
    width: 100%;
    height: calc(100vh - 200px);
    line-height: 0;
    position: relative;
}

.cp_img_01 {
    width: 100%;
    height: 100%;
}

.goods-right-box04 {
    position: absolute;
    top: 20px;
    right: 240px;
    width: 500px;
    height: auto;
}

.goods-cpimg {
    width: 100%;
    height: auto;
}

.body-top {
    width: 100%;
    height: 100%;
    background-color: #fff;
}


.body-top-box01 {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    background-color: #333;
    justify-content: space-between;
}


.box01-img-box {
    width: 500px;
    height: auto;
    float: left;
    position: relative;
}


.cp-img-02 {
    width: 100%;
    height: 100%;
    position: relative;
}

.hover-img {
    position: absolute;
    width: 490px;
    height: 490px;
    opacity: 0;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    background-color: #33333347;
    padding: 5px;
    z-index: 1;
    line-height: 490px;
    text-align: center;
    transition: 1s;
}


.hover-span {
    padding: 120px 90px;
    width: 300px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    transition: 1s;
}


.hover-img:hover {
    opacity: 80%;
    transition: 1s;
}

.box01-text-right {
    float: right;
    flex: 0.5;
    height: 100%;
}


.box01-right-title {
    display: block;
    width: 650px;
    height: 90px;
    font-size: 2rem;
    font-weight: 600;
    margin: 18px 0px;
    line-height: 100px;
    color: #333;
    letter-spacing: 6px;
    border-bottom: 1px solid #fff;
    text-indent: 1ch;
}


.box01-text-container {
    width: 500px;
    height: 200px;
    padding: 20px 20px 48px 20px;
    background-color: #00000098;
    border-radius: 10px;
}


.box-size {
    width: 500px;
    height: auto;
}


.box01-right-text {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    color: #fff;
    text-indent: 2ch;
    font-weight: 500;
}


.body-box02 {
    width: 100%;
    height: 100%;
}


.box02-container {
    width: 100%;
    height: 100%;
    padding: 48px 0px 96px 0px;
    
    background-color: #f8f8f8;
}


.box02-cpimg-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.cpimg_box {
    width: 350px;
    height: 350px;
    margin: 25px;
    position: relative;
}


.cpimg-image {
    width: 100%;
    height: 100%;

}


.hover-box02 {
    position: absolute;
    top:0px;
    left: 0px;
    width: 350px;
    height: 1px;
    background-color: #ff5900aa;
    transition-property: height;
    transition-duration: 1s;
}


.hover-span02 {
    width: 200px;
    height: 30px;
    padding: 50px;
    line-height: 30px;
    color: #fff;
    font-size: 1rem;
    opacity: 0;
    transition-duration: 0.5s;
} 


.cpimg_box:hover .hover-box02 {
    height: 349px;
    transition-duration: 1s;
}


.cpimg_box:hover .hover-span02 {
    opacity: 100%;
    transition-duration: 1.5s;
}


.body-box03 {
    width: 100%;
    height: 100%;
    line-height: 0px;
}
.box03_img {
    width: 100%;
    height: auto;
}


.body-box04 {
    width: 100%;
    height: 100%;
    padding: 48px 0px;
}


.box04-w {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}


.body-box06 {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    padding: 0px !important;
   
}


.body-box06-box {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    padding:0px 0px 40px 0px;
    background-color: #333333 !important;
   
}


.body-top-box01 .block {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    /* padding: 95px 50px 10px 50px; */
    overflow: hidden;
    /* background-color: yellow; */
}


.body-top-box01 .lunbo-img {
    width: 100%;
    height: auto;
}


.body-top-box01 .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  width: 100%;
  height: auto;
}

.body-top-box01 .el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
  width: 100%;
  height: auto;
}


.text-size {
    font-size: 1.2rem;
    height: 40px;
    line-height: 40px;
    color: #333;
}


.title-size{
    font-size: 4rem;
    letter-spacing: 1rem;
    font-weight: 700;
    color: #fff;
    font-family: '锐字潮牌真言简2.0免费准粗' !important;
}


.cpimg_box .info {
    height: 100px !important;
    margin-top: -20px;
}

.cpimg_box h3 {
    margin-top: -80px !important;
}

.cpimg_box p {
    margin-top: -100px !important;
}
.el-carousel__container {
    width: 100%;
    height: 100%;
}
.el-carousel__indicators  {
    display: none;
}
.el-carousel {
    height: auto;
    width: 100%;
}
</style>
