<template>

   <div class="common-layout">
    <el-container>
      <div class="home-box">
        <el-header>
          <my-header></my-header>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </div>
      <el-footer>
        <my-footer></my-footer>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import MyHeader from '@/components/Header.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
  name: 'MyApp',
  components: {
    MyHeader,
    MyFooter
  },
  methods: {

    
  }

}
</script>

<style>
.home-box {
  width: 100%;
  background-image: url('./images/17.jpg');
  background-size: 100%;
  float: left;
  height: auto;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

.el-header  {
  z-index: 9999;
  --el-header-padding: 0 !important;
}

.el-main {
  width: 100%;
  height: 100%;

  --el-main-padding: 0 0px !important;
}

.el-footer {
  width: 100%;
  --el-footer-padding: 0 0px !important;
}

.float {
  width: 100px;
  height: 400px;
  background-color: yellow;
  margin: 0px 30px;
  position: absolute;
  top: 1200px;
  right: 40px;
}

.el-affix {
  float: right;
  margin-right: 50px;
  width: 100px;
  height: 100px;
}

.el-button {
  display: inline-block;
  width: 100px;
  height: 100px;
}
</style>
