<template>
    <div class="show-container">
        <div class="show_box" v-for="item in imagesList" :key="item.id">
            <img class="show-img" :src="item.src" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name: 'MyShow',
    data() {
        return {
            imagesList: [
                { id: 1, src: require('../images/int_04.jpg')},
            ]
        }
    }
}
</script>
<style>


.show-container {
    width: 100%;
    height: 100%;
}


.show_box {
    width: 100%;
    height: 100%;
    line-height: 0;
}


.show-img {
    width: 100%;
    height: auto;
    line-height: 0;
}


</style>