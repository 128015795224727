<template>
    <div class="footer-container">
        <div class="footer-top-w">
            <div class="footer-top">
                <div class="footer-logo-box">
                    <img class="footer-logo" src="../images/hqhk-logo02.png" alt="">
                </div>
            </div>
        </div>
        <div class="footer-w">
           <div class="footer-w-top">
            <div class="footer-bottom-box footer-bottom-box01">
                <div class="bottom-box">
                    <p class="bottom-title">友情链接</p>
                    <div class="ul-box">
                        <ul class="footer-ul">
                            <li class="bottom-li">
                                <a class="li-a" href="http://www.airkunming.com">昆明航空</a>
                            </li>
                            <li class="bottom-li">
                                <a class="li-a" href="https://www.chinaexpressair.com">华夏航空</a>
                            </li>
                            <li class="bottom-li">
                                <a class="li-a" href="https://www.redair.cn">湖南航空</a>
                            </li>
                            <li class="bottom-li">
                                <a class="li-a" href="https://www.loongair.cn">长龙航空</a>
                            </li>
                            <li class="bottom-li">
                                <a class="li-a" href="https://www.tibetairlines.com.cn">西藏航空</a>
                            </li>
                            <li class="bottom-li">
                                <a class="li-a" href="https://www.ynairport.com">云南机场集团</a>
                            </li>
                        </ul>
                    </div>
                </div>
           </div>
           <div class="footer-bottom-box footer-bottom-box02">
                <div class="bottom-box">
                    <p class="bottom-title">了解详情</p>
                    <router-link to="/home">系统构成</router-link>
                    <router-link to="/goods">公司产品</router-link>
                    <router-link to="/partner">伙伴关系</router-link>
                    <!-- <router-link to="/ouser">如何购买</router-link> -->
                </div>
           </div>
           <div class="footer-bottom-box footer-bottom-box03">
                <div class="bottom-box">
                    <p class="bottom-title">联系我们</p>
                    <div class="ul-box lx-box">
                        <div class="title-name">
                            <span class="contant number">18608782520</span>
                        </div>
                        <div class="title-name">
                            <span class="name">邮箱：</span>
                            <span class="contant">appylj@me.cor</span>
                        </div>
                        <div class="title-name">
                            <span class="name">地址：</span>
                            <span class="contant">云南省昆明市大板桥街道办事处长水航城12号地块7-1-102号</span>
                        </div>
                    </div>
                    <img class="footer-ewm" src="../images/tims_new/erweima.png" alt="">
                </div>
                
           </div>
           </div>
           <hr>
           <div class="bottom-text">
                <span class="text-info">© {{ nowDate }} 云南航齐航空科技有限公司 股权所有&nbsp; </span>
                <span class="text-info"> / 隐私权保护</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MyFooter',
    data() {
        return {
            nowDate: new Date().getFullYear()
        }
    },

    methods: {

    }
    
}
</script>
<style>


.router-link-exact-active {
    color: #999;
    display: block;
    font-size: 1.1rem;
    height: 60px;
    line-height: 60px;
}


.bottom-box a {
    color: #999;
    display: block;
    height: 20px;
    line-height: 20px;
    font-size: 0.8rem;
    text-decoration: underline;
}


.top-a:hover .gz {
    background-color: rgb(144, 140, 140);
}


.top-a:hover .iconfont {
    color: #c40000;
}


.footer-container {
    width: 100%;
    height: 500px;
    background-color: #2f3036;
}


.footer-w {
    width: 1200px;
    margin: 0 auto;
    height: 400px;
    background-color: #2f3036;
}


.footer-top {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    background-color: #2f3036;
    border-radius: 0;
}


.footer-logo-box {
    height: 100px;
    width: 300px;
    float: left;
}


.footer-logo {
    height: 100px;
    width: auto;
    text-align: left;
}


.footer-top-right {
    width: 400px;
    height: 100px;
    float: right;
    line-height: 100px;
}


.footer-top-w {
    width: 100%;
    height: 100px;
    background-color: #2f3036;
}


.gz-right {
    width: 400px;
    height: 100px;
    display: flex;
    justify-content: space-between;
}


.gz {
    width: 50px;
    height: 50px;
    background-color: #fff;
    margin: 25px 25px;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
}


.gz .iconfont {
    font-size: 2rem;
    color: #666;
}


.footer-bottom-box {
    width: 400px;
    height: 300px;
}


.footer-bottom-box01 {
    width: 300px;
}


.footer-bottom-box02 {
    width: 300px;
}


.footer-bottom-box03 {
    width: 600px;
    position: relative;
}


.footer-ewm {
    background-color: #fff;
    position: absolute;
    top: 50px;
    right: 100px;
    width: 100px;
    height: 100px;
    float: right;
    padding: 0.5rem;
}


.bottom-box {
    width: 300px;
    height: 240px;
    margin: 60px 50px;
}


.bottom-title {
    width: 100%;
    height: 50px;
    text-align: left;
    line-height: 50px;
    font-size: 1rem;
    margin: 0px;
    color: #999;
}


.footer-ul {
    list-style-type: none;
    padding: 0px;
}


.ul-box {
    width: 100%;
    height: 200px;
}


.bottom-li {
    width: 100%;
    height: 25px;
    text-align: left;
    line-height: 25px;
}


.li-a {
    display: block;
    width: 100%;
    height: 25px;
    line-height: 25px;
    text-align: left;
    font-size: 0.8rem;
    color: #999;
    text-decoration: underline;
}


.li-a:hover {
    color: #c40000;
}


.name {
    display: inline-block;
    width: 40px;
    text-align: left;
    height: 50px;
    font-size: 0.8rem;
    color: #999;
}


.contant {
    width: 250px;
    height: 20px;
    font-size: 0.8rem;
    color: #999;
    float: right;
}


.lx-box {
    margin-top: 20px;
    height: 200px;
}


.xq-a {
    display: block;
    margin-top: 20px;
    font-size: 0.8rem;
    color: #999;
    text-decoration: underline;
}


.xq-a:hover {
    color: #c40000;
}


.bottom-text{
    width: 1200px;
    height: 100px;
    text-align: center;
}


.text-info {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 0.8rem;
    color: #999;
}


.footer-w-top {
    width: 1200px;
    height: 300px;
    display: flex;
    justify-content: space-between;
}


.number {
    display: block;
    height: 80px;
    width: 300px;
    line-height: 80px;
    font-size: 1.5rem;
    margin-top: -14px;
    color: #fff;
    font-weight: 700;
}

</style>