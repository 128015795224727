import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Css from '@/index.css'

import 'hover.css'

import 'animate.css'

import './ihover-gh-pages/src/ihover.min.css'

import "animate.css/animate.min.css";

import '@/font/iconfont.css'

// import  '../src/imagehover.css-master/css/imagehover.min.css'
import '../src/imagehover.css-master/css/imagehover.min.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app =  createApp(App)
app.use(store)
app.use(router)
app.use(Css)
app.use(ElementPlus)
app.mount('#app')
