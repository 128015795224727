<template>
    <el-carousel indicator-position="outside" width="1200px" height="500px">
        <el-carousel-item v-for="item in imagsList" :key="item.id">
          <img :src="item.src" alt="">
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
    name: 'MyLunbo',
    data() {
      return {
        imagsList: [
          {
            id:1,
            src: require('../images/004.jpg')
          },
          {
            id:2,
            src: require('../images/005.jpg')
          },
          {
            id:3,
            src: require('../images/004.jpg')
          },
          {
            id:4,
            src: require('../images/005.jpg')
          },
        ]
      }
    }
}
</script>
<style>


.el-carousel__item h3 {
  display: flex;
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}


.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}


.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}


</style>