<template>
    <div class="cp01-container">
        <el-card class="cp01_card">
            <!-- <img class="com-cp-img" src="../images/int_03.jpg" alt=""> -->
            <div class="col-sm-6">
 
                <!-- normal -->
                <div class="ih-item square effect6 from_top_and_bottom"><a href="#">
                    <div class="img"><img src="../images/daiti.jpg" alt="img"></div>
                    <div class="info">
                        <h3>Heading here</h3>
                        <p>Description goes here</p>
                    </div></a>
                </div>
                <!-- end normal -->

            </div>
        </el-card>
        <el-card class="cp01_card_02">
            <div class="card-02-1">
                <p class="cptd">产品特点</p>
                <ul>
                    <li>
                        <view class="iconfont">&#xe656;</view>
                        Android 11
                    </li>
                    <li>
                        <view class="iconfont">&#xe746;</view>
                        主机 IP67
                    </li>
                    <li>
                        <view class="iconfont">&#xe6a8;</view>                    
                        5.2" IPS 高清大屏
                    </li>
                    <li>
                        <view class="iconfont">&#xe65c;</view>
                        8000mAh 大容量主机电池
                    </li>
                    <li>
                        <view class="iconfont">&#xe852;</view>
                        NFC 读写 (选配)
                    </li>
                </ul>
            </div>
            <div class="card-02-2">
                <p class="cpgn">产品功能</p>
                <ul>
                    <li>
                        <view class="iconfont">&#xe6c8;</view>                      
                        条码扫描 (选配)
                    </li>
                    <li>
                        <view class="iconfont">&#xe7b2;</view>
                        蓝牙 5.0
                    </li>
                    <li>
                        <view class="iconfont">&#xe67c;</view>    
                        4G 全网通 / 双频 Wi-Fi
                    </li>
                    <li>
                        <view class="iconfont">&#xe67e;</view>
                        1300万高清摄像头
                    </li>
                   
                    <li>
                        <view class="iconfont">&#xe661;</view>
                       Impinj E710 / R2000 芯片
                    </li>
                </ul>
            </div>
        </el-card>
    </div>
</template>
<style>
.cp01-container {
    width: 100%;
    height: 400px;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
}
.cp01_card {
    width: 400px;
    height: 400px;
    margin: 0;
}
.com-cp-img {
    width: 100%;
    height: 100%;
}
.cp01_card_02 {
    width: 800px;
    height: 400px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
}
.card-02-1 {
    width: 360px;
    height: 360px;
    margin: 0px 30px 0px 0px;
    float: left;

}
.card-02-2 {
    width: 360px;
    height: 360px;
    margin: 0px;
    float: right;
}
.cptd, .cpgn {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    background-color: #abaaaf;
    margin: 0;
    padding: 10px 0px;
}
.cp01_card_02 ul {
    list-style-type: none;
}
.cp01_card_02 .iconfont {
    font-size: 1.5rem;
    float: left;
}
.cp01_card_02 li {
    width: 250px;
    height: 50px;
    line-height: 50px;
}
view {
    padding: 0px 10px;
}


</style>