<template>
  <div class="header-container">
   <div class="header-center">
       <div class="header-left"  :default-active="$route.path">
           <router-link 
           v-for="(item, index) in headers"
           :key="index"
           :to="item.path"
           
           @click.native="chageColor(index)" >{{ item.content }}</router-link>
       </div>
       
       <div class="header-logo">
           <img class="logo-img" src="../images/hqhk-logo.png" alt="">
       </div>
   </div>
  </div>
</template>
<script>



export default {
   name: 'MyHeader',
   data() {
       return{
           isActive: 5,
           headers: [
               {
                   path: '/home',
                   content: '首页'
               },
               {
                   path: '/goods',
                   content: '产品与服务'
               },
               {
                   path: '/partner',
                   content: '合作伙伴'
               },
           ]
       }
   },
   methods: {
       chageColor(index) {
           this.isActive = index
       },
   }
   
}
</script>
<style scoped>

a {
   display: inline-block;
   width: auto;
   padding: 0px 30px;
   line-height: 59px;
}


.header-left {
   float: right;
}


.header-container {
  background-color: #fff;
  position: fixed;
  z-index: 999999;
  border-radius: 0px;
  top: 0px;
   width: 100%;
   height: 59px;
   margin: 0 auto;
   border-bottom: 1px solid rgba(221, 221, 221, 0.799);
}


.header-center {
   width: 1200px;
   height: 59px;
   margin: 0 auto;
}


.header-ul {
   width: 500px;
   height: 59px;
   margin: 0px;
   padding: 0px;
   float: left;
}


.header-li {
   height: 59px;
   padding: 0px 20px;
   line-height: 59px;
   float: left;
}


.header-logo {
   width: 190px;
   height: 59px;
   margin:0px 0px;
   float: left;
}


.logo-img{
   width: 100%;
   height: auto;
}


.active {
   height: 59px;
   background-color: rgba(184, 204, 244, 0.607);
   font-size: 16px;
   font-weight: 700;
   color: #333;
}


.header-left a {
    font-size: 0.9rem;
}


</style>
