<template>
    <div class="home-container">
        <div class="w home-w">
                <div class="home-text-div">
                   <div class="home-title">
                        <span class="home-text-span animate__animated animate__zoomIn">用物联网力量进一步提高工作效率</span><br><br>
                        <span class="span_02 animate__animated animate__zoomIn">
                            <span class="xhx">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            云南航齐航空科技有限公司
                        </span>
                   </div>
                </div>
            
        </div>

        <div class="home-contant-div">
            <div class="contant-w">
                <home-top></home-top>
            </div>
        </div>
    </div>
</template>
<script>
import HomeTop from '@/components/HomeTop.vue'

export default {
    name: 'MyHome',
    components: {
        HomeTop
    }
}
</script>
<style>
.home-container {
    width: 100%;
    height: 100%;


}
.home-w {
    width: calc(100vw - 480px);
    height: calc(100vh - 300px);
    margin: 0 240px;
    background-color: rgba(0, 0, 0, 0.245);
}
.home-text-div {
    height: 100%;
    position: relative;
    text-align: center;
}
.home-text-span {
    display: block;
    width: 100%;
    margin: 0 auto;
    font-size: 2.5rem;
    color: #fff;
    font-weight: 700;
    height: 150px;
    line-height: 150px;
    text-align: center;
    letter-spacing: 15px;    
}
.span_02 {
    display: inline-block;
    height: 50px;
    text-align: right;
    font-size: 1.5rem;
    color: #fff;
    margin-left: 400px;
    letter-spacing: 4px;
}
.home-title {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.xhx {
    text-decoration: underline;
    float: left;
}
.home-contant-div {
    width: 100%;
    height: 100%;
    background-color: #fff;
    /* background-color: #c6c6c6; */

}
.contant-w {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}





</style>