<template>
    <div class="xn-container">
        <div class="body-box06">
            <p class="box06_p" style="color: #fff;">产品信息</p>
            <div class="cp_list_box">
                <el-menu>
                    <el-menu-item>
                        <router-link class="tip" to="/goods/cp01">C72-UHF-RFID</router-link>
                    </el-menu-item>
                    <el-menu-item>
                        <router-link class="tip" to="/goods/cp02">C71内置UHF-RFID</router-link>
                    </el-menu-item>
                </el-menu>
            </div>
                <div class="cp-xingneng">
                    <router-view></router-view>
                </div>
         </div>

    </div>
</template>
<script>
export default {
    name: 'MyCpxn'
}
</script>
<style>


.body-box06 {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    padding: 48px 0px;
   
}


.box06_p{
        margin: 0 auto;
        font-size: 3rem;
        padding: 20px 0px 0px 0px;
        width: 100%;
        letter-spacing: 5px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        font-weight: 700;
}


.cp-xingneng {
    width: 100%;
    height: 100%;
}


.router-link {
    float: left;
}


.body-box06 li {
    float: left;
    padding: 0px 20px;
}


.cp_list_box {
    width: 100%;
    height: 50px;
    padding: 16px 0px;
    text-align: center;
}


.cp_list_box  .el-menu {
    margin: 0 auto;
    color: #fff;
}
.el-menu-item {
    color: #fff;
}
.tip {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 1px;
}
.el-menu-item:hover {
    background-color: #333 !important;
    color: #fff;
}


</style> 