<template>
    <div class="frends_container">
        <div class="frends_w">
            <div class="frends_box">
                <el-card class="f-card" v-for="item in logoList" :key="item.id">
                    <img class="frends_img_01" :src="item.src" alt="">
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MyFrends', 
    data() {
        return {
            logoList: [
                {id: 1, src: require('../images/kmhk.jpg')},
                {id: 2, src: require('../images/huaxia.png')},
                {id: 3, src: require('../images/hunan.png')},
                {id: 4, src: require('../images/tims_new/41513.jpg')},
                {id: 5, src: require('../images/tims_new/41514.jpg')},
                {id: 6, src: require('../images/tims_new/41515.jpg')},
                {id: 7, src: require('../images/tims_new/xizanghangkong.jpg')},

            ]
        }
    }
}
</script>

<style>
.frends_container {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    /* padding: 5rem 0px; */
}


.frends_w {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}


.frends_box {
    width: 1000px;
    margin: 10px auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.frends_box_01 {
    width: 200px;
    height: 200px;
    margin: 10px 10px !important;
    border-radius: 10px;
    float: left;
    background-color: #fff;
}


.el-card {
    width: 240px;
    height: 180px;
    margin: 50px 10px;
    border-radius: 10px;
    float: left;
    margin-top: 48px;
}


.frends_img_01 {
    width: 200px;
    height: auto;
}


.frends_img_02 {
    width: 200px;
    height: auto;
    margin-top: 35px;
}
.el-card__body {
    box-shadow:(100, 5, 6, #000);
}


.f-card {
    width: 240px;
    height: auto;
}


</style>