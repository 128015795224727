import { createRouter, createWebHashHistory } from 'vue-router'

import MyApp from '@/App.vue'
import MyHome from '@/views/MyHome.vue'
import Ouser from '@/views/Ouser.vue'
import MyGoods from '@/views/MyGoods.vue'
import MyFrends from '@/views/MyFrends.vue'
import CP_01 from '../components/cp_01.vue'
import CP_02 from '../components/cp_02.vue'
import CP_03 from '../components/cp_03.vue'
import CP_04 from '../components/cp_04.vue'
import CP_05 from '../components/cp_05.vue'
// import app from '@/ihover-gh-pages/dist/scripts/app'

const routes = [
  {
    path: '/',
    name: 'app',
    component: MyApp,
    redirect: '/home'
  },
  {
    path: '/home',
    component: MyHome,
  },
  {
    path: '/ouser',
    component: Ouser,
  },
  {
    path: '/goods',
    component: MyGoods,
    children: [
      {
        path: 'cp01',
        component: CP_01,
      },
      {
        path: 'cp02',
        component: CP_02
      },
      {
        path: 'cp03',
        component: CP_03
      },
      {
        path: 'cp04',
        component: CP_04
      },
      {
        path: 'cp05',
        component: CP_05
      },
    ]
  },
  {
    path: '/partner',
    component: MyFrends,
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router


router.beforeEach((to, from, next) => {
  if(to.path === '/goods') {
    next( {path: '/goods/cp01'})
  }else {
    next()
  }

})
